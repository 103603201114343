






























































































































































































































































import {Component, Vue, Watch} from 'vue-property-decorator';
import HelloWorld from './components/HelloWorld.vue';
import LanguageSelector from '@/components/LanguageSelector.vue';
import LocalizedElement from '@/components/LocalizedElement.vue';
// import store, { SetLanguage } from '@/store'
import { mapState } from 'vuex';

@Component({
  components: {
    LanguageSelector,
    HelloWorld,
    LocalizedElement,
  },
  computed: mapState([
    'language',
  ]),
})
export default class App extends Vue {

  private beforeMount() {
    this.$gtag.query('event', 'screen_view', {
      app_name: 'MyApp',
      screen_name: 'Home page',
    });
  }

  // @Watch('setLanguage', { immediate: false, deep: false })
  // setLanguage (newVal: string) {
  //   console.log('XXXXXX', newVal)
  //   if (newVal) {
  //     // this.addParamsToLocation({ interval: String(newVal) })
  //     console.log('XXXXXX', newVal)
  //   }
  // }

}
