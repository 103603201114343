














import {Component, Vue, Watch} from 'vue-property-decorator';
import store, {SetLanguage} from '@/store';
import { mapState } from 'vuex';

@Component(
    {
      computed: mapState([
          'language']),
    },
)
export default class LanguageSelector extends Vue {

  public lng = 'dk';

  @Watch('language', { immediate: true, deep: true })
  public ssL(newVal: any): void {
    // console.log('@Watch ', newVal);
    this.lng = newVal;
  }


    public sL(lang: string) {
    store.dispatch({
      type: SetLanguage,
      value: lang,
    });
  }

  public isActive(lang: string) {
    // console.log('isActive', lang)
    if (lang === this.lng) {
      return true;
    }
  }

}
